@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');

.globalfooterhome {
    width: 100%;
    height: 62vh;

    background-color: #545454;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

}

/*.iconsspace {
    display: grid;
    width: 175px;
    height: 30px;
    margin-top: 4vh;

    grid-template-columns: 1fr 1fr 1fr;
} */

.icon {
    width: 50px;
    height: 25px;

    color: #FFFFFF;
}

.legend {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;

    color: #FFFFFF;

    margin-top: 7vh;

    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
}

/* .arealogo {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}*/

.logofooter {
    width: 10%;
    margin: auto;
  display: block;
}

.logofooterproj {
    width: 8%;
    margin: auto;
  display: block;
}

.officialinfo {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.1em;

    color: #FFFFFF;

    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
}

@media (max-width: 480px){
    .logofooter, .logofooterproj {
        width: 25%;
    }
    
  }

  @media (min-width: 2000px){
    .officialinfo {
        font-size: 22px;
        line-height: 24px;
    }

   
    .legend {
        font-weight: 400;
        font-size: 42px;
        line-height: 42px
    }

    .iconsspace {
        width: 400px;
        height: 50px;
    }
    
    .icon {
        width: 100px;
        height: 50px;
    }
  }

