@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');

.btn-do, .btn-do-proj, .btn-do-studio {


    width: 265px;
    height: 32px;

    border-radius: 100px;

    border: none;
    color: black;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
    letter-spacing: 0.05em; 
    font-weight: 500;
}

.btn-do {
    background: linear-gradient(to right, #CB6D1E,  #FBD08B);
}

.btn-do-proj {
    background: linear-gradient(to right, #4FAF35, #37B496);
}

.btn-do-studio {
    background: linear-gradient(to right, #FFE42D, #E84033);
}

@media (min-width: 2000px){ 
    .btn-do {
        width: 565px;
        height: 80px;

        font-size: 30px;
    }
    
}