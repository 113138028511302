.behind-area{
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
}

.area-slider{
  width: 80%;
  height: 200px;


  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center;     /* Centraliza verticalmente */
  
}
.slider-container {
  width: 100%;
}

.img-area-normal1, .img-area-normal2, .img-area-normal3{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente o conteúdo interno */
  align-items: center;  
}
.img-area-normal1 {
  margin-top: 20%;
}

.img-area-normal2{
  margin-top: 17.5%;
}

.img-area-normal3{
  margin-top: 7%;
}
.img-area-normal4{
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente o conteúdo interno */
  align-items: center; 
  margin-top: 2.5%;
  
}
.img-carousel {
  width: 80%;
  height: auto;
  text-align: center;
  margin-left: 14%;
  filter: grayscale(100%) brightness(40%);
}


.img-carousel-4{
  width: 60%;
  height: auto;
  text-align: center;
  margin-left: 22%;
  filter: grayscale(100%) brightness(40%);
}

.custom-next-arrow, .custom-prev-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.custom-next-arrow {
  right: -8%;
}

.custom-prev-arrow {
  left: -8%;
}

/* Estilização adicional */
.custom-next-arrow::before, .custom-prev-arrow::before {
  font-size: 30px;
  color: #545454; /* Altere a cor conforme necessário */
}


