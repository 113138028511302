* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}


.comtem {
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: #fff;
  width: 100vw;
  height: 1000px;
}
.topdiv{
  display: flex;
  flex-direction: column;
  background-color: red;
  width: 100%;
}
.card {
  display: flex;
  /*justify-van-content: center;*/
  align-items: start;
  max-width: 90vw;
  flex-direction: column;
  background-color: rgb(245, 242, 242);
  padding: 50px;
  position: relative; 
 /* border-left: 5px solid #590BA8;  Cor da primeira borda 
  border-right: 5px solid #6DFD70;
  box-shadow: 
  -5px 0 0 0 #6DFD70, Sombra do lado esquerdo 
   5px 0 0 0 #590BA8; Sombra do lado direito Ajuste para a distância entre as bordas */

   border-radius: 10px;
 margin-top: 3%;


  /*margin-left: 27.8%;
  margin-top: -40%;*/
 

}

.switch {
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
  border-radius: 25px;
  background-color: #ccc;
  cursor: pointer;
  position: relative;
}

.side {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  color: white;
  position: absolute;
}

.blue {
  background-color: #007bff;
  left: 0;
  border-radius: 25px 0 0 25px;
}

.red {
  background-color: #dc3545;
  right: 0;
  border-radius: 0 25px 25px 0;
}

.disabled {
  opacity: 0.5;
}

.enabled {
  opacity: 1;
}

.van-content {
  max-width: 600px;
  position: relative;

  background-color: red;
}

.title {
  font-family: 'Questrial', Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 42px;
  color: black;
  font-weight: 600;
  font-size: 40px;
  text-align: start;
}

.secondtitle {
  margin-top: 5%;
}
iframe{
  margin-bottom: 50px;
}
.card > .van-description{
  font-family: 'Questrial', Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 26px;
  font-size: 24px;
  color: black;
  text-align: start;
  margin-top: 2%;

}

.video-container {
  width: 100%;
  /* Estilos para o vídeo aqui */
}

.container {
  width: 80%;
  max-width: 600px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.component-container {
  overflow-x: hidden;
  transition: transform 0.3s ease-in-out;
}

/* Estilos para os botões */
button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  outline: none;
}

button:hover {
  background-color: #0056b3;
}
