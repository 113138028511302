@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  min-height: 100%;
}

body {
  background-color: #000;
  background-size: cover;
  -webkit-font-smoothing: antialised !important;
}
.logomaindesc {
  position: relative;
 /* z-index: 15;*/

  width: 50px;

  top: -4vh;
  /*left: 44vw;*/
}

.lp-global {
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.div-out {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: black;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999;
  top: 0;
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  
}


.it {
  position: fixed;
  top: 10px;
  right: 10px;
  height: 3rem;
  width: 3rem;
  padding: 5px;
  background-color: rgba(0,0,0, 0.4);
  color:  #fff;
  cursor: pointer;
}

.itnext {
  position: fixed;
  bottom: 50%;
  right: 10%;
  height: 3rem;
  width: 3rem;
  padding: 5px;
  background-color: rgba(0,0,0, 0.4);
  color:  #fff;
  cursor: pointer;
}

.itprevious {
  position: fixed;
  bottom: 50%;
  left: 10%;
  height: 3rem;
  width: 3rem;
  padding: 5px;
  background-color: rgba(0,0,0, 0.4);
  color: blue;
  cursor: pointer;  
}

.img-out {
  width: auto;
  max-width: 90%;
  max-height: 90%;
}

.img-responsive {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}

.gallery-global {

width: 1300px;
height: 100%;

}

/*.text-areatwo::before{
  
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
    background-image: url('../asssets/logocompany.png');
    background-repeat: no-repeat;
    background-position: 05% -105%;
    background-size: auto;
    background-color: #000;
    z-index: -999;
}*/

.lp-content { 

 width: 100%;
 /*height: 500px;*/

 display: flex;
 flex-direction: column;

 align-items: center;
 text-align: center;
 
}
/*
.logofooter {
  width: 60px;
  margin-top: 0 ;
  margin-bottom: 7.5vh;
  margin-left: 0;
  margin-right: 0;
}*/

.arealogodesc {
  width: auto;
  height: auto;

  margin-top: 20vh;


  display: flex;


}

.text-areaone h2.text-promo {
  font-family: 'Questrial', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #fff;

}

h2.text-promo-green {
  font-family: 'Questrial', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.2em;
  color: black;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.text-areatwo h2.text-promo {
  font-family: 'Questrial', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #fff;
}

.text-areathree h2.text-promo {
  font-family: 'Questrial', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #fff;
}


  .text-areaone {
    width: 100%;
    height: 180px;
    padding: 5%;

  } 

  .text-areathree {
    width: 100%;
    height: 180px;
    padding: 5%;
  }

  .text-areatwo {
    width: 100%;
    height: 450px;
    padding: 5%;
  }


@media (max-width: 991px){
  .gallery-global{
      width: 400px;
      padding-left: 5%;    
      height: 100%;  
  }
}

@media (max-width: 480px){
  .gallery-global{
      width: 400px;
      height: 100%;
      padding-left: 5%; 
  }
 
   .text-areatwo, .text-areathree {
    width: 100%;
    padding: 5%;
  }

  .text-areaone {
    margin-top: 5vh;
    height: 415px;
    padding: 5%;
  }

  
  .text-areathree {
    margin-top: 5vh;
    height: 325px;
  }

  .bg-green {
    background-color: #6FFD71;
    border-radius: 7px; 
  }
}

@media (max-width: 2000px){
  .gallery-global{
      width: 400px;
      height: 100%;
      padding-left: 5%; 
  }
 
   .text-areatwo, .text-areathree {
    width: 100%;
    padding: 5%;
  }

  .text-areaone {
    margin-top: 5vh;
    height: 415px;
    padding: 5%;
  }

  
  .text-areathree {
    margin-top: 5vh;
    height: 325px;
  }

  .bg-green {
    background-color: #6FFD71;
    border-radius: 7px; 
  }
}