/* ProgressBar.css */
.progress-bar {
    width: 100%;
    background-color: #e0e0df;
    border-radius: 13px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 20px;
    background-color: #76c7c0;
    text-align: center;
    line-height: 20px;
    color: white;
    border-radius: 13px 0 0 13px;
    transition: width 0.5s ease-in-out;
  }
  