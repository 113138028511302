.wrapper {
    display: grid;

    /*grid-template-rows: 2;*/
    grid-gap: 60px;

   grid-template-columns: 300px 300px 300px;

    align-items: center;
    justify-content: center;

    margin-top: 35vh;
}

@media (max-width: 991px) {
    .wrapper {
        display: grid;

        grid-template-rows: auto;
        grid-gap: 60px;

        align-items: center;
        justify-content: center;

        margin-top: 26vh;
    }
}

@media (max-width: 480px) {
    .wrapper {
        display: grid;

        /*grid-template-rows: 2;*/
        grid-gap: 60px;

        grid-template-columns: 100%;

        align-items: center;
        justify-content: center;

        margin-top: 35vh;
    }
}
.img-client1 {
    width: 250px;
    height: 60px; 
}

.img-client2 {
    width: 300px;
    height: 90px;
}

.img-client3 {
    width: 250px;
    height: 110px;
}

.img-client4 {
  width: 300px;

  margin-top: 50px;
}

.img-client5 {
   width: 300px;
   height: 200px;
   margin-top: -30px;
   margin-left: 20px;
}