@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');

.globalsocialmedia {
    width: 300px;
    height: 100px;

    display: flex;
    flex-direction: column;
}

.icons-space {
    display: grid;

    grid-template-columns: 50px 250px;
    grid-template-rows: 35px 35px 35px;

    grid-row-gap: 20px;
}

.content-text {
    padding: 5px;
}

.icon-social-home {
    width: 50px;
    height: 25px;

    color: #CB6D1E;
} 

.icon-social-proj {
    width: 50px;
    height: 25px;

    color: #4FAF35;
} 

.content-text-home h2 {
    text-decoration: none;
    color: #CB6D1E;
    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
   
    margin-top: 0.4vh;
    letter-spacing: 0.05em;
    text-align: left;
   
}

.content-text-proj h2 {
    text-decoration: none;
    color: #4FAF35;
    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
   
    margin-top: 0.6vh;
    letter-spacing: 0.05em;
    text-align: left;  
}

@media (min-width: 2000px){
    .globalsocialmedia {
        width: 600px;
        height: 300px;
    }

    .icons-space {
        grid-template-columns: 100px 350px;
        grid-template-rows: 70px 70px 70px;
    
        grid-row-gap: 40px;
    }

    .icon-social {
        width: 100px;
        height: 50px;
    }
    
    .content-text h2 {
        font-size: 28px;
        line-height: 30px;
       
        letter-spacing: 0.1em; 
    }
}





