.line {
    position: relative;
    width: 341.01px;
    height: 0px;
   
    top: 88px;

 
    
    border: 1px solid #D2FA7B;
    transform: rotate(0.34deg);

    box-shadow: 0px 4px 4px rgba(210, 250, 123, 0.35);
}