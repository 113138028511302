@font-face {
    font-family: 'Chillax-Variable';
    src: url('../fonts/Chillax-Variable.woff2') format('woff2'),
         url('../fonts/Chillax-Variable.woff') format('woff'),
         url('../fonts/Chillax-Variable.ttf') format('truetype');
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
  }
  

/* src/DropdownMenu.css */
.dropdown-menu {
    position: relative;
    display: inline-block;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .menu-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 130%;
    left: -13vw;
    background: linear-gradient(45deg, #4FAF35, #37B496);
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 200px;
    z-index: 999;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6);
    overflow: hidden;
   
  }
  
  .menu-list::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.164); /* Camada branca com opacidade */
    z-index: -1; /* Para garantir que fique atrás do conteúdo do menu */
  }

  
  .menu-list li {
    text-align: center;
    margin: 5px;
  }
  
  .menu-list li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #fff;
  font-family: 'Chillax-Variable', sans-serif;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  position: relative; /* Para garantir que o hover não seja afetado pelo ::before */
  z-index: 1;
  }
  
  .menu-list li a:hover {
    background-color: #f0f0f0;
    color: #545454;
  }
  
  @media (max-width: 600px) {
    .menu-list {
      width: 100%;
    }
  
    .menu-list li a {
      padding: 15px;
    }
  }
  