@font-face {
  font-family: 'Chillax-Variable';
  src: url('../fonts/Chillax-Variable.woff2') format('woff2'),
       url('../fonts/Chillax-Variable.woff') format('woff'),
       url('../fonts/Chillax-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

body {
  background-color: #fff;
  font-family: 'Chillax-Variable', sans-serif;
}

h2.calcular{
    color: black;
    cursor: pointer;
    width: 68px;
}

h2.calcular:hover{
    color: green;
}
.images-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  padding: 10px;
  text-align: center;
}

.small-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}
.image-wrapper {
  position: relative;
  margin: 5px;
}
.small-image, .large-image {
  display: block;
  width: 100%;
  height: auto;
}
.overlay-prp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.35); /* Camada branca com baixa opacidade */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Chillax-Variable", "Open Sans", "Helvetica Neue", sans-serif;
  color: #000;
  font-weight: 600;
}
.image-wrapper:hover .overlay-prp  {
  background-color: rgba(255, 255, 255, .04); /* Camada menos opaca */
  cursor: pointer;
}
.image-wrapper:hover .overlay-text {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 5px;
}


.gradient-line-studio-prp {
  height: 3px;
  background: linear-gradient(to right, #FFE42D, #E84033);
  width: 41%; /* Define o comprimento da linha */
  margin-bottom: 74%;
  position: absolute;
  z-index: 999;
}


.small-image {
  width: 250px; /* ajuste conforme necessário */
  margin-bottom: 10px;
}

.large-image {
  width: 520px; /* ajuste conforme necessário */
}

.divider {
  width: 2px;
  height: 100%;
  background-color: black; /* ajuste conforme necessário */
}



.imgcontainer{
  width: 99%;
  height: 1200px;
  
  margin-left: 4px;
}

/* Proposal.css */
.container {
  font-family: "Chillax-Variable", "Open Sans", "Helvetica Neue", sans-serif;
  max-width: 600px;
  margin: 10vh auto;
  padding: 20px;
  background-color: #f8f9fa; /* Cor de fundo mais claro */
  color: #333; /* Cor do texto */

  height: 1200px;

}
  
  h1, h3 {
    font-family: "Chillax-Variable", "Open Sans", "Helvetica Neue", sans-serif;
    color: #545454;
    text-align: center;
    font-weight: 400;
  }

  h2 {
    font-size: 18px;
    font-family: "Chillax-Variable", "Open Sans", "Helvetica Neue", sans-serif;
    color: #545454;
    font-weight: 500;
  }
  
  form {
    margin-top: 20px;
    display: block;
    font-family: "Chillax-Variable", "Open Sans", "Helvetica Neue", sans-serif;
    color: #545454;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-family: "Chillax-Variable", "Open Sans", "Helvetica Neue", sans-serif;
    color: #545454;
  }
  
  select,
  input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ced4da; /* Cor da borda */
    border-radius: 5px;
  }
  
  select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%230099FF' d='M2.3 7.3l7 7 7-7c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-7.7 7.7c-.4.4-1 .4-1.4 0l-7.7-7.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
  }
  
  input[type="checkbox"] {
    margin-right: 5px;
    vertical-align: middle;
  }
  
  button {
    background-color: #007aff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .send-btn {
    margin-top: 150px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .servicos-selecionados-lista {

    height: 200px;
  }

  .servicos-selecionados ul li {
    margin-bottom: 5px;
  }
  
  .servicos-selecionados h2 {
    color: #007aff;
  }
  