@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  min-height: 100%;
  background-color: rgb(237, 238, 238);
}

body {

  background-color: #000;
  background-size: cover;
  -webkit-font-smoothing: antialised !important;
}

.global {
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;
}

body  .titles-home {
  position: relative;
  
  width: 129px;

  top: 20px;

  font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.1em;


  color: #CB6D1E;
} 

.page {

  align-items: center;
  
  text-align: center;

  display: flex;
  flex-direction: column;
}

.portfolio, .clientes, .contato, .vantagens {
  min-height: 600px;
}

.vantagens {
  background-color: hsl(121, 97%, 71%);
  width: 100%;
  padding: 20px;
}

.paggina {
  padding: 20px;
  background-color: hsl(121, 97%, 71%);
}

.paggina h2, p {
  font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 24px;
  color: #CB6D1E;
  /*text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);*/
}

.paggina h2{
  font-weight: 600;
  font-size: 42px;
  letter-spacing: .09em;
  color: #CB6D1E;
  text-align: center;
}
.paggina p.texto {
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.05em;
  margin: 100px 0;
}

.titulo {
  transform: translateX(-800%);

  font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 24px;
  color: #CB6D1E;
  font-weight: 400;
  font-size: 24px;
}

.content-contact {
  align-items: center;
  
  text-align: center;
  justify-content: center;

  display: flex;
  flex-direction: row;

  margin-top: 20vh;

  width: 800px;
  height: 300px;
}

.smallline {
    right: 1.35vh;
    top: 5vh;
    position: relative;
    width: 3.5px;
    height: 250px;
    background: linear-gradient(to right, #CB6D1E,  #FBD08B);
    border: none;
}

.company {
  height: 800px;
  width: 100%;
  padding: 5%;
}

.company:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .1;
  background-image: url('../asssets/logocompany.png');
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-size: auto;
  background-color: #000;
  z-index: -999;
  
}

/* nao vai adiantar alterr o TOP, pq fica a diferença sobrando la em*/

.about-company {
  /* z-index: 1999;*/
  width: 100%;
  height: 200px;
  margin: 10%;
  padding: 3% 5%;
  display: flex;
}

.about-company p {
  color: #545454;
  font-family: 'Chillax-Variable';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
}

.btns a{
  display: grid;

  grid-template-rows: 50px;
  grid-gap: 60px;

  grid-template-columns: 300px 265px;

  align-items: center;
  justify-content: center;
  padding: 12px 0;

  max-width: 800px;
}

.btns-contact a {
  display: grid;

  grid-template-rows: 50px 50px;
  grid-template-columns: 100%;


  width: 265px;
  height: 100px;



  margin-bottom: 8vh;



  padding: 10% 22%;
}

.gradient-line-proj{
  height: 3.5px;
  background: linear-gradient(to right, #CB6D1E,  #FBD08B);
  width: 85%;
  margin: 50px 0 0 0;
 }

 .gradient-line-projj{
  height: 75%;
  background: linear-gradient(to right, #CB6D1E,  #FBD08B);
  width: 0.5%;
  margin: 50px 0 0 0;
 }

@media (max-width: 480px){

  .btns a{
    display: grid;

    grid-template-rows: 50px 50px;
    grid-template-columns: 100%;


    max-width: 100%;
    
    margin-top: 40vh;
  }

  .gradient-line-projj{
    height: 3.5px;
    background: linear-gradient(to right, #CB6D1E,  #FBD08B);
    width: 85%;
    margin: 50px 0 0 0;
   }
  .btns-contact a {
    display: grid;

    grid-template-rows: 50px 50px;
    grid-template-columns: 100%;


    max-width: 100%;
    height: 85px;

    margin-top: 15vh;

    padding: 0;
  }

  .content-contact {
    align-items: center;
    
    text-align: center;
  
    display: flex;
    flex-direction: column;

    width: 100%;
  }

  .about-company p {
    margin-top: 15vh;
    line-height: 28px;
  }

  .company:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
    background-image: url('../asssets/logocompanymobile.png');
    background-repeat: no-repeat;
    background-position: 50% 53%;
    background-size: auto;
    background-color: #000;
    z-index: -999;
  }




  

  

}

@media (min-width: 2000px){

  body  .titles {
    position: relative;
    
    width: 129px;
  
    top: 130px;
  
    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 24px;
    letter-spacing: 0.1em;
  
    color: #FFFFFF;
  } 

  .company {
    height: 1100px;
  }

  .about-company {
    /* z-index: 1999;*/
    width: 100%;
    height: 200px;
    
    display: flex;
  }
  
  .about-company p {
    color: white;
    font-family: 'Chillax-Variable';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0.05em;

    display: flex;
  }

  .btns a{
    display: grid;

    grid-template-rows: 100px;
    grid-gap: 200px;

    grid-template-columns: 500px 565px;

    align-items: center;
    justify-content: center;
    

    max-width: 1300px;
  }

  .contato {
    min-height: 1000px;
  }

  .content-contact {    
    width: 1600px;
    height: 600px;  
  }

  .btns-contact a {
    grid-template-rows: 115px 115px;
  
    width: 530px;
    height: 350px;
  }

  .smallline {
    height: 500px;  
    top: 1vh;
  }
}

.content-home h1.text-content-home, h2.text-content-home {
  position: absolute;
  z-index: 15;
  transform: rotate(0.34deg);

  font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 200;
  line-height: auto;
  letter-spacing: 0.1em;
  color: white;
}

.content-home h1 {
  font-size: 22px;
  left: 4vw;
  top: 40vh;
}

.content-home h2 {
  font-size: 12px;
  left: 4vw;

  font-weight: 200;
  top: 46vh;
}

.content-home h3 {
  position: absolute;
  z-index: 15;
  transform: rotate(0.34deg);

  font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
  line-height: auto;
  letter-spacing: 0.1em;
  color: #fff;


}

.content-home h3 {
  font-weight: 200;
  font-size: 12px;
  right: 5vw;
  top: 46.5vh;
}

.logomain {
  position: absolute;
  z-index: 15;

  width: 10%;

  top: 8vh;
  left: 4vw;
}

/* all style dropdown menu */
:root {
  --bg: green;
  --bgtwo: red;
  --bg-accent: white;
  --text-color: white;
  --opacity: 1;
  --nav-size: 80px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
}

a {

  text-decoration: none;
  cursor: pointer;
}

.testunic {
  /*fill: var(--text-color);  */
  width: 30px;
  height: 30px;
}

.nav-item {
  position: absolute;
  margin-left: 88%;
  margin-top: 3%;
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  z-index: 599;
}



.icon-buutton {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size); 
  background: none;
  border: 2px solid white;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}
/* Dropdown menu */
.dropdown{
  position: absolute;
  top: 65px;
  width: 300px;
  transform: translateX(-86%);
  background-color: none;  
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden; 
}

.menu-item{
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  font-size: 17px;
  cursor: pointer;
}


.menu-item:hover {
  background-color: #525357;
  color: white;
  filter: brightness(1.2);
}


/* espaço em que o icone ocupa */
.icon-button-drop  {
  fill: var(--bgtwo);
  
}

.drop-icons {
  width: 30px;
  height: 40px;
  color: white;
  opacity: .7;
  margin-right: 15px;
  
}