.advantages-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #F5F5F5;
    padding: 4%;
    box-sizing: border-box;
  }
  .gradient-line {
    height: 3px;
    background: linear-gradient(to right, #4FAF35, #37B496);
    width: 25%; /* Define o comprimento da linha */
    margin: 10px 0 0 0;
  }
  .gradient-line-proj{
    height: 3.5px;
    background: linear-gradient(to right, #CB6D1E,  #FBD08B);
    width: 25%; /* Define o comprimento da linha */
    margin: 50px 0 0 0;
  }
  .text-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .van {
    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    color: #464646;
    text-align: left;
  }
  .title-v  {
    font-size: 25px;
    margin: 0;
  }
  
  .subtitle-v {
    font-size: 40px;
    margin: 50px 0 0 0;
    
  }
  
  .info-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

   
  }
  
  .points {
    font-size: 75px;
    margin: 0 0 50px 0;
    color: #464646;
    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
    font-weight: 400;
  }
  
  .learn-more-button {
    padding: 10px 20px;
    font-size: 16px;
    background: linear-gradient(to right, #4FAF35, #37B496);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
    font-weight: 400;
  }
  
  .learn-more-button:hover {
    background: linear-gradient(to right, #5ab142, #53b69f);
  }
  
  @media (max-width: 768px) {
    .advantages-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .info-section {
      margin-top: 20px;
    }
  
    .title {
      font-size: 14px;
    }
  
    .subtitle {
      font-size: 18px;
    }
  
    .points {
      font-size: 28px;
    }
  
    .learn-more-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  