.lineabsolute, .lineabsoluteproj {
    position: absolute;
    width: 1225px;
    height: 2px;
    top: 45.4vh;
    left: 4vw;
    z-index: 15;
    transform: rotate(0.34deg);   
   
    border: none;
}

.lineabsolute {
    background: linear-gradient(to right, #CB6D1E,  #FBD08B);
}

.lineabsoluteproj {
    background: linear-gradient(to right, #4FAF35, #37B496);
}

@media (max-width: 480px) {
    .lineabsolute, .lineabsoluteproj {
        width: 341.01px;
    }    

    
}

@media (min-width: 2000px) {
    .lineabsolute {
        width: 2450px;
    }
}