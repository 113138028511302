
.globalfooter {
    width: 100%;
    height: 60vh;

    background: linear-gradient(to right, #FFE52D, #E83F33);

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

}

.developed {
    width: 120px;
    height: 105px;

    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 90%;
    margin-bottom: 40vh;
}
.developed h3 {
    text-align: center;
    color: #FFFFFF;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 200;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}
.developed img {
    width: 55%;
    margin-bottom: 10px;
    text-align: center;
}

.linkvw {
    text-align: center;
}

.iconsspace {
    display: grid;
    width: 175px;
    height: 30px;
    margin-top: 4vh;

    grid-template-columns: 1fr 1fr 1fr;
}

.icon {
    width: 50px;
    height: 25px;

    color: #FFFFFF;
}

.legend {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;

    color: #FFFFFF;

    margin-top: 7vh;

    font-family: 'Chillax-Variable', Arial, Helvetica, sans-serif;
}

.arealogo {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

   
}

.logofooterm3d {
    width: 10%;
    margin: auto;
  display: block;
}

.officialinfo {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.1em;

    color: #FFFFFF;

    font-family: 'Titillium Web', Arial, Helvetica, sans-serif;
}
.auxxfooter{
    width: 100%;
    height: 100%;
    
}

@media (max-width: 480px){
    .logofooterm3d{
        width: 22%;
    }
    .iconsspace {
        margin-top: 10vh;
     }
     .developed{
        width: 105px;
        left: 70%;
        margin-bottom: 77.3%;
  }

  .legend {
    font-size: 16px;
  
  }
  .globalfooter {
    height: 62vh;
  }
  .auxxfooter{
    margin-top: 2.7vh;
  }

  .arealogo {
    width: 100%;
    height: 190px;
}

  }

  @media (min-width: 2000px){
    .officialinfo {
        font-size: 22px;
        line-height: 24px;
    }

    .legend {
        font-weight: 400;
        font-size: 42px;
        line-height: 42px
    }

    .iconsspace {
        width: 400px;
        height: 50px;
    }
    
    .icon {
        width: 100px;
        height: 50px;
    }
  }

