h2.calcular{
    color: black;
    cursor: pointer;
    width: 68px;
}

h2.calcular:hover{
    color: green;
}

/* Proposal.css */
.container {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    max-width: 600px;
    margin: 10vh auto;
    padding: 20px;
    background-color: #f8f9fa; /* Cor de fundo mais claro */
    color: #333; /* Cor do texto */

    height: 1200px;
  }
  
  h1 {
    color: #007aff;
    text-align: center;
  }

  h2 {
    font-size: 18px;
    color: #007aff;
  }
  
  form {
    margin-top: 20px;
    display: block;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  select,
  input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ced4da; /* Cor da borda */
    border-radius: 5px;
  }
  
  select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%230099FF' d='M2.3 7.3l7 7 7-7c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-7.7 7.7c-.4.4-1 .4-1.4 0l-7.7-7.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
  }
  
  input[type="checkbox"] {
    margin-right: 5px;
    vertical-align: middle;
  }
  
  button {
    background-color: #007aff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .send-btn {
    margin-top: 150px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .servicos-selecionados-lista {

    height: 200px;
  }

  .servicos-selecionados ul li {
    margin-bottom: 5px;
  }
  
  .servicos-selecionados h2 {
    color: #007aff;
  }
  